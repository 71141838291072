import Donation from './Donation'
import HomeThree from './HomeThree'

const onClick = () => {
  window.open('http://localhost:3000/donation', '_blank')
}

const routes = [
  { path: '/', component: <HomeThree linkRegister={onClick} /> },
  { path: '/donation', component: <Donation linkRegister={onClick} /> },
]

export default routes
