import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import '@vidstack/react/player/styles/base.css'
import '@vidstack/react/player/styles/plyr/theme.css'

import { MediaPlayer, MediaProvider } from '@vidstack/react'
import {
  PlyrLayout,
  plyrLayoutIcons,
} from '@vidstack/react/player/layouts/plyr'

function TwoVideoPlayers(props) {
  const [dataTitle] = useState({
    first_title: props.firstTitle,
    first_subtitle: props.firstSubTitle,

    second_title: props.secondTitle,
    second_subtitle: props.secondSubTitle,

    third_title: props.thirdTitle,
    third_subtitle: props.thirdSubtitle,

    urlFirstVideo: props.urlFirstVideo,
    urlSecondVideo: props.urlSecondVideo,
    urlThirdVideo: props.urlThirdVideo,

    title: props.title,
  })
  const { t } = useTranslation()
  return (
    <section id='projects' className='tf-section tf_CTA'>
      <div className='container relative'>
        {/* <div className='overlay'></div> */}
        <div className='row'>
          <div className='col-md-12'>
            <div
              className='tf-title'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title '>
                {t(dataTitle.title)} <br className='show-desktop' />{' '}
                {t(dataTitle.title_2)}
              </h2>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='tf-title left '
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title'>{t(dataTitle.first_title)}</h2>
              <p className='sub'>{dataTitle.first_subtitle}</p>
              <MediaPlayer
                title='Uma escola que surpreende a cada oportunidade.'
                src={dataTitle.urlFirstVideo}
              >
                <MediaProvider />
                <PlyrLayout icons={plyrLayoutIcons} />
              </MediaPlayer>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='tf-title left'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title'>{t(dataTitle.second_title)}</h2>
              <p className='sub'>{dataTitle.second_subtitle}</p>
              <MediaPlayer
                title='Uma escola que surpreende a cada oportunidade.'
                src={dataTitle.urlSecondVideo}
              >
                <MediaProvider />
                <PlyrLayout icons={plyrLayoutIcons} />
              </MediaPlayer>
            </div>
          </div>
        </div>
        {/* <div className='row'>
          <div className='col-md-6'>
            <div
              className='tf-title left '
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title'>{t(dataTitle.first_title)}</h2>
              <p className='sub'>{dataTitle.third_subtitle}</p>
              <MediaPlayer
                title='Uma escola que surpreende a cada oportunidade.'
                src={dataTitle.urlThirdVideo}
              >
                <MediaProvider />
                <PlyrLayout icons={plyrLayoutIcons} />
              </MediaPlayer>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default TwoVideoPlayers
