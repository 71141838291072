import { React, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import * as i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Clarity from '@microsoft/clarity'

import AOS from 'aos'
import routes from './pages'
import '../src/assets/fonts/font-awesome.css'

import './scss/component/_section.scss'
import './scss/component/_box.scss'
import './scss/component/_tf-section.scss'
import './scss/component/_reponsive.scss'

import Header from './components/header'
import Footer from './components/footer'
import br from './translations/pt-br'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      br: br,
    },
    lng: localStorage.getItem('language'), // if you're using a language detector, do not define the lng option
    fallbackLng: 'br',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

function App() {
  const projectId = 'oyewo71fgi'
  Clarity.init(projectId)
  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])
  return (
    <>
      <Header />
      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} exact />
        ))}
      </Routes>

      <Footer />
    </>
  )
}

export default App
