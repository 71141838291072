import React, { useState } from 'react'
import PropTypes from 'prop-types'
import '../styles.scss'
import ProjectItem from '../project_item_v2'
import { useTranslation } from 'react-i18next'

Knowledge.propTypes = {
  data: PropTypes.array,
}

function Knowledge(props) {
  const { data } = props

  const [dataTitle] = useState({
    title: props.title,
    title_2: props.title2,
  })

  const { t } = useTranslation()

  return (
    <section id='areas' className='tf-section project_3'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div
              className='tf-title'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title '>
                {t(dataTitle.title)} <br className='show-desktop' />{' '}
                {t(dataTitle.title_2)}
              </h2>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='project-box-style2_wrapper'>
              {data.slice(props.index_1, props.index_2).map(item => (
                <ProjectItem key={item.id} item={item} />
              ))}
            </div>
          </div>
          <div className='col-md-12'>
            <div className='project-box-style2_wrapper top-project-box'>
              {data.slice(props.index_3, props.index_4).map(item => (
                <ProjectItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Knowledge
