import React from 'react'

import PageTitle from '../components/pagetitle'

import { Link } from 'react-router-dom'

function Donation() {
  return (
    <div className='inner-page'>
      {
        <PageTitle
          title='Donations'
          title_='Você já recebe tanto, agora é hora de doar...'
        />
      }

      <section className='tf-section tf-contact pt60'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-md-12'
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <div
                className='col-md-4'
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className='wrap-btn' style={{ width: '300px' }}>
                  <Link
                    to='https://donorbox.org/pessoa-fisica'
                    className='tf-button style2'
                    target='_blank'
                    style={{ width: '100%', textAlign: 'center' }}
                  >
                    Doação anônima
                  </Link>
                </div>
              </div>
              <div
                className='col-md-4'
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className='wrap-btn' style={{ width: '300px' }}>
                  <Link
                    to='https://forms.gle/fp8rMN18aw2F7AeAA'
                    className='tf-button style2'
                    target='_blank'
                    style={{ width: '100%', textAlign: 'center' }}
                  >
                    Doação pessoa física
                  </Link>
                </div>
              </div>
              <div
                className='col-md-4'
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className='wrap-btn' style={{ width: '300px' }}>
                  <Link
                    to='https://forms.gle/dsU6Yf9f6hRSap8x8'
                    className='tf-button style2'
                    target='_blank'
                    style={{ width: '100%', textAlign: 'center' }}
                  >
                    Doação pessoa jurídica
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Donation
