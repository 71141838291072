import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'

import { Link } from 'react-router-dom'

function Footer() {
  const [isVisible, setIsVisible] = useState(false)
  // const [selectedLanguage, setSelectedLanguage] = useState('en')
  const { t } = useTranslation()

  // const changeLanguageHandler = lang => {
  //   i18n.changeLanguage(lang)
  //   localStorage.setItem('language', lang)
  // }

  // const languages = [
  //   { code: 'en', name: 'English' },
  //   { code: 'br', name: 'Português' },
  //   // More languages
  // ]

  // const handleChangeLanguage = event => {
  //   setSelectedLanguage(event.target.value)

  //   changeLanguageHandler(event.target.value)
  //   // You should put this into a context so the whole app will be able to access it and avoid prop drilling
  // }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const onClickIcons8 = () => {
    window.open('https://icons8.com.br/')
  }

  const onClickInstagram = () => {
    window.open('https://instagram.com/escolaedufuturo')
  }

  const onClickFacebook = () => {
    window.open('https://www.facebook.com/escolaedufuturo')
  }

  const onClickLindedIn = () => {
    window.open('https://www.linkedin.com/company/edufuturobr/')
  }

  const onClickYouTube = () => {
    window.open('https://www.youtube.com/@Edufuturo')
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])
  return (
    <footer id='footer'>
      <div className='footer-main'>
        <div className='container'>
          <div className='row'>
            <div className='footer-logo'>
              <div className='logo_footer'>
                <img
                  src={require('../../assets/images/logo/logo.png')}
                  alt=''
                />
              </div>
              <p>{t('Awakening A New Consciousness In The World')}</p>
            </div>
            <div className='widget'>
              <h5 className='widget-title'>{t('Contact us')}</h5>
              <ul className='widget-link contact'>
                <li>
                  <p>{t('Address')}</p>
                  <Link to='#'>
                    Av. Amazonas, 901 - Universitário, Caruaru - PE, 55016-430
                  </Link>
                </li>
                <li>
                  <p>{t('Phone')}</p>
                  <Link to='#'>+55 81 98373-7777</Link>
                </li>
                <li className='email'>
                  <p>{t('Email')}</p>
                  <Link to='#'>contato@edufuturo.com.br</Link>
                </li>
              </ul>
            </div>
            {/* <div className='widget support'>
              <h5 className='widget-title'>{t('Support')}</h5>
              <ul className='widget-link'>
                <li>
                  <Link to='/faqs'>FAQs</Link>
                </li>
                <li>
                  <Link to='/contact'>{t('Contact')}</Link>
                </li>
              </ul>
            </div> */}
            <div className='widget link'>
              <h5 className='widget-title'>{t('Quick Link')}</h5>
              <ul className='widget-link'>
                <li>
                  <Link to='/'>{t('Home')}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='container'>
          <div className='wrap-fx'>
            <div className='Copyright'>
              Copyright © 2024. {t('Designed by Edufuturo.')} Icons by{' '}
              <Link onClick={onClickIcons8} to='#'>
                Icon8
              </Link>
            </div>
            {/* <div>
              <select value={selectedLanguage} onChange={handleChangeLanguage}>
                {languages.map(language => (
                  <option key={language.code} value={language.code}>
                    {language.name}
                  </option>
                ))}
              </select>
            </div> */}
            <ul className='social'>
              <li>
                <Link onClick={onClickInstagram}>
                  <svg
                    fill='#fff'
                    width='18px'
                    height='18px'
                    viewBox='0 0 18 18'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <title>Instagram icon</title>
                    <path d='M9 0C6.555 0 6.25 0.011 5.29 0.054 4.331 0.099 3.679 0.25 3.105 0.473c-0.592 0.229 -1.094 0.538 -1.595 1.038S0.701 2.513 0.473 3.105C0.25 3.679 0.098 4.331 0.054 5.29 0.009 6.25 0 6.555 0 9s0.011 2.75 0.054 3.71c0.045 0.958 0.196 1.611 0.419 2.185a4.425 4.425 0 0 0 1.038 1.595A4.425 4.425 0 0 0 3.105 17.527c0.575 0.222 1.227 0.374 2.185 0.419C6.25 17.991 6.555 18 9 18s2.75 -0.011 3.71 -0.054c0.958 -0.045 1.611 -0.197 2.185 -0.419a4.425 4.425 0 0 0 1.595 -1.038 4.425 4.425 0 0 0 1.038 -1.595c0.222 -0.574 0.374 -1.227 0.419 -2.185 0.045 -0.96 0.054 -1.265 0.054 -3.71s-0.011 -2.75 -0.054 -3.71c-0.045 -0.958 -0.197 -1.612 -0.419 -2.185a4.425 4.425 0 0 0 -1.038 -1.595A4.387 4.387 0 0 0 14.895 0.473c-0.574 -0.223 -1.227 -0.374 -2.185 -0.419C11.75 0.009 11.445 0 9 0m0 1.62c2.402 0 2.689 0.012 3.637 0.053 0.877 0.041 1.354 0.187 1.67 0.311 0.422 0.163 0.72 0.358 1.036 0.672 0.314 0.315 0.509 0.614 0.672 1.036 0.123 0.317 0.27 0.793 0.31 1.67 0.043 0.95 0.053 1.234 0.053 3.637s-0.011 2.689 -0.055 3.637c-0.046 0.877 -0.192 1.354 -0.316 1.67a2.85 2.85 0 0 1 -0.674 1.036 2.805 2.805 0 0 1 -1.035 0.672c-0.315 0.123 -0.799 0.27 -1.676 0.31 -0.956 0.043 -1.237 0.053 -3.644 0.053s-2.689 -0.011 -3.644 -0.055c-0.878 -0.046 -1.362 -0.192 -1.677 -0.316a2.775 2.775 0 0 1 -1.034 -0.674 2.73 2.73 0 0 1 -0.675 -1.035c-0.124 -0.315 -0.269 -0.799 -0.315 -1.676 -0.034 -0.945 -0.046 -1.237 -0.046 -3.633s0.012 -2.689 0.046 -3.646c0.046 -0.877 0.191 -1.361 0.315 -1.675 0.158 -0.427 0.359 -0.72 0.675 -1.036 0.314 -0.314 0.608 -0.517 1.034 -0.673 0.315 -0.124 0.788 -0.271 1.666 -0.316 0.956 -0.034 1.237 -0.045 3.644 -0.045zm0 2.758a4.622 4.622 0 1 0 0 9.243 4.622 4.622 0 1 0 0 -9.243M9 12c-1.657 0 -3 -1.343 -3 -3s1.343 -3 3 -3 3 1.343 3 3 -1.343 3 -3 3m5.885 -7.804a1.081 1.081 0 0 1 -2.16 0 1.08 1.08 0 0 1 2.16 0' />
                  </svg>
                </Link>
              </li>
              <li>
                <Link onClick={onClickFacebook}>
                  <svg
                    fill='#fff'
                    width='18px'
                    height='18px'
                    viewBox='-0.135 0 0.54 0.54'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M0.28 0.004v0.086h-0.051a0.047 0.047 0 0 0 -0.038 0.012l0 0a0.053 0.053 0 0 0 -0.01 0.035v0h0.095l-0.013 0.096H0.182V0.54H0.083V0.294H0V0.198h0.083V0.127a0.125 0.125 0 0 1 0.034 -0.094l0 0A0.122 0.122 0 0 1 0.207 0h0a0.543 0.543 0 0 1 0.077 0.004l-0.003 0z' />
                  </svg>
                </Link>
              </li>
              <li>
                <Link onClick={onClickLindedIn}>
                  <svg
                    fill='#fff'
                    width='18px'
                    height='18px'
                    viewBox='-0.003 -0.003 0.03 0.03'
                    xmlns='http://www.w3.org/2000/svg'
                    preserveAspectRatio='xMinYMin'
                    className='jam jam-linkedin'
                  >
                    <path d='M0.025 0.015v0.009h-0.005v-0.009c0 -0.002 -0.001 -0.004 -0.003 -0.004 -0.001 0 -0.002 0.001 -0.003 0.002 0 0 0 0.001 0 0.001v0.009h-0.005s0 -0.015 0 -0.016h0.005v0.002l0 0h0v0c0.001 -0.001 0.002 -0.003 0.005 -0.003 0.004 0 0.006 0.002 0.006 0.007M0.003 0C0.001 0 0 0.001 0 0.003c0 0.002 0.001 0.003 0.003 0.003h0c0.002 0 0.003 -0.001 0.003 -0.003C0.006 0.001 0.005 0 0.003 0zM0 0.024h0.005V0.008H0z' />
                  </svg>
                </Link>
              </li>
              <li>
                <Link onClick={onClickYouTube}>
                  <svg
                    fill='#fff'
                    width='18px'
                    height='18px'
                    viewBox='0 0 0.54 0.54'
                    xmlns='http://www.w3.org/2000/svg'
                    data-name='Layer 1'
                  >
                    <path d='M0.517 0.218a0.191 0.191 0 0 0 -0.02 -0.093 0.066 0.066 0 0 0 -0.039 -0.022A1.755 1.755 0 0 0 0.27 0.096a1.777 1.777 0 0 0 -0.188 0.007 0.065 0.065 0 0 0 -0.033 0.017c-0.02 0.019 -0.022 0.051 -0.025 0.078a1.08 1.08 0 0 0 0 0.146 0.216 0.216 0 0 0 0.007 0.045 0.071 0.071 0 0 0 0.016 0.031 0.064 0.064 0 0 0 0.034 0.018 1.012 1.012 0 0 0 0.146 0.007c0.079 0.001 0.148 0 0.229 -0.006a0.065 0.065 0 0 0 0.034 -0.018 0.056 0.056 0 0 0 0.014 -0.022 0.238 0.238 0 0 0 0.012 -0.076c0.001 -0.013 0.001 -0.089 0.001 -0.102M0.219 0.334V0.195l0.133 0.07c-0.037 0.021 -0.087 0.044 -0.133 0.069' />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {isVisible && <Link onClick={scrollToTop} to='#' id='scroll-top'></Link>}
    </footer>
  )
}

export default Footer
