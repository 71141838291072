const menus = [
  {
    id: 1,
    name: 'Home',
    links: '',
  },
  {
    id: 2,
    name: 'About',
    links: '#',
    namesub: [
      {
        id: 1,
        sub: 'Quem Somos?',
        links: '#about',
      },
      {
        id: 2,
        sub: 'Na Mídia',
        links: '#media',
      },
      {
        id: 4,
        sub: 'Áreas-Chave',
        links: '#areas',
      },
      {
        id: 3,
        sub: 'Metodologia',
        links: '#metodology',
      },
    ],
  },
  {
    id: 5,
    name: 'Salas do Futuro',
    links: '#salas',
  },
  {
    id: 6,
    name: 'Projetos',
    links: '#projects',
  },
]

export default menus
