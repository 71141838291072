import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, A11y, Pagination } from 'swiper'
import { Tabs, TabPanel } from 'react-tabs'

Project5.propTypes = {
  data: PropTypes.array,
}

function Project5(props) {
  const { data } = props

  const [dataTab] = useState([
    {
      id: 1,
      title: 'oncoming',
    },
    {
      id: 2,
      title: 'Upcoming',
    },
    {
      id: 3,
      title: 'ended',
    },
  ])

  return (
    <section id='metodology' className='tf-section project_2'>
      <div className='container w_1280'>
        <div className='row'>
          <div className='col-md-12'>
            <div
              className='tf-title mb20'
              data-aos='fade-up'
              data-aos-duration='800'
            >
              <h2 className='title'>{props.title}</h2>
            </div>
          </div>
          <div className='col-md-12'>
            <div
              className='flat-tabs'
              data-aos='fade-up'
              data-aos-duration='1000'
            >
              <Tabs>
                {/* <TabList className='menu-tab'>
                  {dataTab.map(idx => (
                    <Tab className='fs-14 h6' key={idx.id}>
                      {idx.title}
                    </Tab>
                  ))}
                </TabList> */}

                {dataTab.map(idx => (
                  <TabPanel key={idx.id} className='content-tab'>
                    <div className='content-inner'>
                      <Swiper
                      modules={[A11y, Autoplay, Pagination]}
                                          
                                          pagination={{ clickable: true }}
                        className='slider-3'
                        autoplay={{ delay: 10000, disableOnInteraction: false }}
                        spaceBetween={30}
                        slidesPerView={2}
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                          },

                          867: {
                            slidesPerView: 3,
                          },
                        }}
                        grid={{
                          rows: 1,
                        }}
                      >
                        {data.slice(64, 69).map(item => (
                          <SwiperSlide key={item.id}>
                            <div className='project-box-style4'>
                              {/* <div className='image'>
                                <div className='img_inner'>
                                  <img src={item.img} alt='' />
                                  <img
                                    className='shape'
                                    src={require('../../../assets/images/common/shape.png')}
                                    alt='Edufuturo'
                                  />
                                </div>
                                <div className='label'>1ST Phase</div>
                              </div> */}
                              <div className='content'>
                                <h5 className='heading'>{item.title}</h5>
                                <p className='desc'>{item.desc}</p>
                                {/* <ul>
                                  <li>
                                    <p className='text'>{item.title_1}</p>
                                    <p className='price'>{item.price_1}</p>
                                  </li>
                                  <li>
                                    <p className='text'>{item.title_2}</p>
                                    <p className='price'>{item.price_2}</p>
                                  </li>
                                  <li>
                                    <p className='text'>{item.title_3}</p>
                                    <p className='price'>{item.price_3}</p>
                                  </li>
                                  <li>
                                    <p className='text'>{item.title_4}</p>
                                    <p className='price'>{item.price_4}</p>
                                  </li>
                                  <li>
                                    <p className='text'>{item.title_5}</p>
                                    <p className='price'>{item.price_5}</p>
                                  </li>
                                </ul> */}
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Project5
