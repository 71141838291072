import React from 'react'

import { useTranslation } from 'react-i18next'

import dataBanner from '../assets/fake-data/data-banner'
import dataMedia from '../assets/fake-data/data-media.js'
import dataProject from '../assets/fake-data/data-project.js'
import About from '../components/about'
import SalaDoFuturo from '../components/about/salaDoFuturo'
import Fachada from '../components/about/fachada'
import Banner3 from '../components/banner/banner_v3'
import InMedia from '../components/partner/inMedia'
import Knowledge from '../components/project/project_v9'
import TwoVideoPlayers from '../components/video-player/TwoVideoPlayers'
import Metodology from '../components/project/project_v5'

function HomeThree(props) {
  const { t } = useTranslation()
  return (
    <div className='header-fixed main home3 counter-scroll'>
      {
        <Banner3
          id='top'
          button='Faça sua doação agora!'
          data={dataBanner}
          title='Transforme Vidas Agora!'
          sub='Empoderando indivíduos e comunidades, a educação tecnológica e a inclusão digital abrem portas para um futuro mais igualitário e próspero. Ao se unir a essa transformação, você não só impacta vidas, mas também constrói uma sociedade mais conectada, inovadora e cheia de oportunidades. Cada passo dado é uma mudança positiva no caminho para um mundo mais justo e acessível a todos.'
          i18n={t}
          link='/donation'
        />
      }

      <About
        id='about'
        title='Conheça o Núcleo Social do Grupo Edufuturo'
        subtitle='Participe da iniciativa de transformar vidas através da educação tecnológica, inclusão digital e desenvolvimento de habilidades para o futuro. Junte-se a nós na missão de construir um amanhã mais igualitário e inovador para todos.'
        button_url={props.linkRegister}
        i18n={t}
        data={dataProject}
      />

      {<InMedia id='media' data={dataMedia} />}

      {
        <Knowledge
          data={dataProject}
          title='Áreas-Chave de conhecimento'
          button_url={props.linkRegister}
          i18n={t}
          index_1={55}
          index_2={58}
          index_3={58}
          index_4={60}
        />
      }
      {
        <Metodology
          data={dataProject}
          title='Metodologia'
          i18n={t}
          desc={`Diferenciais competitivos: gamificação, aprendizagem ativa, certificação internacional.
              Uso de plataforma própria que gera informações personalizadas sobre o desempenho de cada aluno com foco em competências e habilidades que envolve “hard skills” e “soft skills”, disponibilizada para o padrinho social.
          `}
        />
      }
      {
        <SalaDoFuturo
          id='salas'
          title='Salas do Fututro'
          subtitle='A Sala do Futuro é um ambiente imersivo, equipado com tecnologia interativa, realidade aumentada e inteligência artificial para promover experiências educacionais transformadoras e conectadas ao mundo de amanhã.'
          button_url={props.linkRegister}
          i18n={t}
          data={dataProject}
        />
      }

      {
        <TwoVideoPlayers
          title='Projetos'
          firstSubTitle='Instituto Edufuturo São João da Escócia - Caruaru-PE'
          secondSubTitle='Um dia no Instituto Edufuturo - Caruaru-PE'
          thirdSubtitle='Cáritas - SP'
          urlFirstVideo='https://youtu.be/wWTPRYUKaYw'
          urlSecondVideo='https://youtu.be/CYJ_uiLutHo'
          urlThirdVideo='https://youtu.be/bKXUbgG2ukM'
          button_url={props.linkRegister}
          i18n={t}
        />
      }

{
        <Fachada
          id='instalacoes'
          title='Nossas Instalações'
          subtitle='A Sala do Futuro é um ambiente imersivo, equipado com tecnologia interativa, realidade aumentada e inteligência artificial para promover experiências educacionais transformadoras e conectadas ao mundo de amanhã.'
          button_url={props.linkRegister}
          i18n={t}
          data={dataProject}
        />
      }
    </div>
  )
}

export default HomeThree
