import React from 'react'

import '../styles.scss'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

function Banner3(props) {
  const { data } = props
  return (
    <div id='top' className='page-title st3'>
      <div className='overlay'></div>
      <div className='slider-main'>
        <Swiper modules={[Navigation]} spaceBetween={0} slidesPerView={1}>
          {data.slice(0, 2).map(item => (
            <SwiperSlide key={item.id}>
              <div className='slider-st3'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='box-slider'>
                        <div className='content-box'>
                          <h1 className='title-new'>
                            Você como Agente de Mudanças de uma Sociedade!
                          </h1>
                          {/* <h5 className='title'>
                            Não! Não é uma contribuição, é um privilégio poder
                            DOAR...
                          </h5> */}
                          <p className='title-new'>
                            Transformação Social através da Educação Tecnológica
                            e Inclusão Digital!
                          </p>
                          {/* <p className='sub-title'>{props.i18n(props.sub)}</p> */}
                          <br></br>
                          <div className='wrap-btn'>
                            <Link to={props.link} className='tf-button style2'>
                              Transforme Vidas Agora! <br/> {" "}Faça sua Doação!
                            </Link>
                          </div>
                          {/* <div className='flat-box'>
                            <div className='box-couter'>
                              <p>Potential project</p>
                              <div className='number-content'>
                                <h6 className='count-number2'>700+</h6>
                              </div>
                            </div>
                            <div className='box-couter'>
                              <p>Market cap</p>
                              <div className='number-content'>
                                <h6 className='count-number2'>$570.24 M</h6>
                              </div>
                            </div>
                            <div className='box-couter'>
                              <p>Investors</p>
                              <div className='number-content'>
                                <h6 className='count-number2'>6.297</h6>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        {/* <div className='image'>
                          <img
                            className='img_main'
                            src={require('../../../assets/images/common/img_slider3.png')}
                            alt=''
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

Banner3.propTypes = {
  data: PropTypes.array,
}

export default Banner3
